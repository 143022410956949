.header,
.header__mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 130px 0 130px;
  max-width: 1180px;
  margin: auto;
}

.logo__block {
  padding: 35px 0 35px 0;
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-right: 20px;
}

.menu {
  display: flex;
  padding: 24px 0 24px 0;
  align-items: center;
}

.menu__items {
  display: flex;
  align-items: flex-start;
  margin-right: 48px;
}

.menu__items li {
  font-weight: 400;
}

.menu__items li:hover {
  cursor: pointer;
}

.menu__items li:not(:last-child) {
  margin-right: 32px;
}

.cta {
  padding: 14px 32px;
  background-color: var(--cta-red-color);
  color: white;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  border-radius: 24px;
  white-space: nowrap;
}

.cta:hover {
  cursor: pointer;
  background: #ff4b54;
}

.cta:active {
  background: #d40009;
}

.mobile__menu {
  display: none;
}

.close__menu {
  display: none;
}

.mobile__menu__opened {
  display: none;
}

/* reset mobile layout */
.logomenu__wrapper {
  display: flex;
  width: 100%;
}

@media (max-width: 768px) {
  .nav {
    width: 100%;
    height: 100%;
  }

  .menu__items {
    display: none;
  }

  .mobile__menu__opened {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .menu {
    display: none;
  }

  .header__mobile {
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 35px 0 35px;
    max-width: auto;
    width: 100%;
    background-color: white;
    z-index: 9998;
    position: fixed;
    height: 100vh;
    overflow: scroll;
    touch-action: none;
  }

  .mobile__logomenu__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10%;
  }

  /* button */
  .cta__block__mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10%;
    width: 100%;
  }

  .cta__block__mobile .cta {
    width: 100%;
  }

  .cta__block {
    display: none;
  }

  .mobile__menu__opened .menu__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .mobile__menu__opened .menu__items a {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  .mobile__menu__opened .menu__items li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .mobile__menu {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-basis: 50%;
    padding: 35px 0 35px 0;
  }

  .close__menu {
    display: flex;
  }
}

@media (max-width: 576px) {
  .cta__block__mobile {
    bottom: 23%;
  }

  .mobile__logomenu__wrapper {
    margin-bottom: 20%;
  }
}

@media (max-width: 999px) {
  .header {
    padding: 0px 35px 0px 35px;
  }

  .menu__items {
    margin-right: 36px;
  }

  .menu__items li {
    font-size: 14px;
  }

  .menu__items li:not(:last-child) {
    margin-right: 24px;
  }
}
