.background {
  padding: 10% 10% 10% 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 1;
}

.background::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url('../../images/testimonials/testimonials_bg.svg') bottom
    no-repeat;
  background-size: cover;
  z-index: -2;
}

.section__title {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
}

.text__highlighted {
  color: #0147ff;
}

.testimonials__wrapper__desktop {
  background-color: white;
  border-radius: 24px;
  z-index: 1;
  position: relative;
  max-width: 1180px;
  margin: auto;
  display: block;
}

.testimonials__art {
  opacity: 0.5;
  position: absolute;
  top: 7%;
  left: 6%;
  z-index: 0;
}

.testimonials__art img {
  max-width: 100%;
}

.border__bottom img {
  width: 100%;
}

.testimonials__wrapper__mobile {
  display: none;
  position: relative;
}

.testimonials__wrapper__mobile::before {
  content: '';
  background: url('../../images/testimonials/quotes.svg') no-repeat;
  position: absolute;
  top: -5%;
  left: 5%;
  z-index: 100;
  height: 44px;
  width: 40px;
}

@media (max-width: 768px) {
  .background {
    padding: 15% 10% 15% 10%;
  }

  .testimonials__wrapper__mobile {
    display: block;
  }

  .testimonials__wrapper__desktop {
    display: none;
  }

  .section__title {
    font-size: 32px;
    margin-bottom: 30px !important;
  }

  .testimonials__art img {
    max-width: 38px;
  }
}
