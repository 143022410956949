.footer {
  min-height: 100px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1180px;
  margin: auto;
  padding: 0 5% 0 5%;
}

.copyright {
  order: 1;
}

.menu__items {
  display: flex;
  order: 2;
}

.menu__items a {
  color: var(--footer-links-color) !important;
}

.menu__items li:not(:last-child) {
  margin-right: 32px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 18% 0 10% 0;
  }

  .menu__items {
    flex-direction: column;
    order: 1;
  }

  .copyright {
    order: 2;
  }

  .menu__items {
    margin-bottom: 70px;
    text-align: center;
  }

  .menu__items li:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 32px;
  }
}
