.background {
  width: 100%;
  height: 100%;
  min-height: 850px;
  box-sizing: border-box;
  position: relative;
  padding: 5% 0 0 0;
  overflow: hidden;
}

.text__highlighted {
  color: #0147ff;
}

.section__title {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 50px !important;
}

.works__wrapper {
  margin: 0 auto 60px auto;
  display: flex;
  position: relative;
  min-height: 520px;
  overflow: hidden;
  max-width: 1500px;
}

.first__row,
.second__row {
  display: flex;
  flex-direction: column;
}

.first__row > *:not(:last-child),
.second__row > *:not(:last-child) {
  margin-bottom: 20px;
}

.first__row img,
.second__row img {
  max-width: 325px;
}

.first__row {
  --scroll: 0;
  display: grid;
  grid-template: repeat(36, var(--cardH)) / var(--cardW);
  position: absolute;
  top: 0;
  left: 57%;
  bottom: 75%;
  transform: translateX(-50%) rotateX(45deg) rotateZ(45deg)
    translateY(var(--scroll));
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}

.second__row {
  --scroll: 0;
  display: grid;
  grid-template: repeat(36, var(--cardH)) / var(--cardW);
  position: absolute;
  top: 60%;
  right: 10%;
  transform: translateX(-50%) rotateX(45deg) rotateZ(45deg)
    translateY(var(--scroll));
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}

.art {
  position: absolute;
}

@media (max-width: 1100px) {
  .first__row {
    left: 43%;
    bottom: 80%;
  }
}

@media (max-width: 768px) {
  .background {
    min-height: auto;
    padding: 15% 0 10% 0;
  }

  .first__row {
    left: 65%;
    bottom: 90%;
  }

  .second__row {
    top: 55%;
  }

  .first__row img,
  .second__row img {
    max-width: 200px;
  }

  .section__title {
    font-size: 32px;
    margin-bottom: 32px !important;
  }
}

@media (max-width: 576px) {
  .works__wrapper {
    min-height: 280px;
  }

  .first__row {
    left: 63%;
    bottom: 95%;
  }

  .second__row {
    right: 0;
    top: 75%;
  }

  .first__row img,
  .second__row img {
    max-width: 170px;
  }

  .art {
    top: 4%;
  }

  .art img {
    max-width: 41px;
  }
}
