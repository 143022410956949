.testimonial__wrapper {
  display: flex;
  width: 100%;
  padding: 45px;
  box-sizing: border-box;
  border-radius: 24px;
}

.quotes__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 4em;
  margin-top: 0.5em;
}

.content__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content {
  max-width: 650px;
}

.content__text {
  margin-bottom: 1em;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: var(--text-black-color);
  opacity: 0.64;
}

.content h6 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.author__info img {
  margin-right: 1em;
}

.author__info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rating__desktop {
  display: flex;
  align-items: flex-end;
}

.rating__desktop {
  font-family: Source Serif Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

.rating__mobile {
  display: none;
}

.author__info__inner__mobile {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .testimonial__wrapper {
    background: white;
    border-radius: 24px;
    padding: 32px;
    position: relative;
  }

  .rating__desktop {
    display: none;
  }

  .rating__mobile {
    display: flex;
  }

  .quotes__wrapper {
    position: absolute;
    top: -10%;
    left: 3%;
    display: none;
  }

  .author__info {
    justify-content: space-between;
    align-items: center;
  }

  .author__info img {
    max-width: 56px;
  }

  .content__text {
    text-align: left;
  }
}
