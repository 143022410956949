.background {
  padding: 10% 5% 5% 5%;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 500px;
}

.heart__art {
  max-height: 400px;
}

.section__title {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  color: var(--text-black-color);
  /* override global  CSS rule for headings margins */
  margin-bottom: 40px !important;
}

.text__highlighted {
  color: #0147ff;
}

.numbers__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1030px;
  margin: auto;
}

.numbers__wrapper > *:not(:last-child) {
  margin-right: 40px;
}

@media (max-width: 999px) {
  .background {
    padding: 20% 0 20% 0;
  }

  .numbers__wrapper {
    flex-direction: column;
  }

  .section__title {
    font-size: 32px;
  }

  .numbers__wrapper > *:not(:last-child) {
    margin-bottom: 48px;
    margin-right: 0;
  }
}
