.background {
  padding: 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.background::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url('../../images/clients/clients_bg.svg') bottom no-repeat;
  background-size: cover;
  z-index: -1;
}

.section__title {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  color: var(--text-black-color);
  margin-bottom: 40px !important;
}

.text__highlighted {
  color: #0147ff;
}

.rows__wrapper,
.rows__wrapper__mobile {
  box-sizing: border-box;
  border-radius: 24px;
  background: white;
  max-width: 1180px;
  margin: auto;
}

.rows__wrapper__mobile {
  display: none;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.horizontal__border img {
  width: 100%;
}

@media (max-width: 768px) {
  .rows__wrapper {
    display: none;
  }

  .row {
    grid-template-columns: 1fr 1fr;
  }

  .rows__wrapper__mobile {
    display: block;
  }

  .section__title {
    font-size: 32px;
  }
}
