.vertical_block__wrapper,
.horizontal_block__wrapper {
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.horizontal_block__wrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  width: 50%;
  flex-grow: 1;
}

.vertical_block__wrapper:hover,
.horizontal_block__wrapper:hover {
  background: rgba(246, 249, 255, 1);
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-basis: 33%;
}

.border__right {
  order: 3;
}

.vertical_block__wrapper {
  padding: 15%;
  display: flex;
  flex-direction: column;
  min-height: 590px;
  justify-content: space-around;
  box-sizing: border-box;
}

.horizontal_block__wrapper .block__title {
  margin-bottom: 24px;
}

.horizontal_block__wrapper .img__wrapper {
  order: 2;
}

.horizontal_block__wrapper .content__wrapper {
  margin-right: 25px;
  order: 1;
}

.horizontal_block__wrapper .border__bottom {
  order: 3;
}

.block__title {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 24px;
  opacity: 0.9;
  color: rgba(25, 25, 37, 1);
}

.block__text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  opacity: 0.64;
  line-height: 28px;
  font-size: 14px;
}

.vertical_block__wrapper .img__wrapper {
  min-height: 200px;
}

.vertical_block__wrapper .block__title {
}

.border__bottom img,
.border__bottom img {
  width: 100%;
}

.border__right {
  display: block;
}

.border__bottom {
  display: none;
}

@media (max-width: 999px) {
  .border__right {
    display: none;
  }

  .border__bottom {
    display: block;
  }

  .horizontal_block__wrapper {
    flex-direction: column;
    align-items: flex-start;
    padding: 18% 10% 18% 10%;
    width: auto;
  }

  .vertical_block__wrapper {
    padding: 18% 10% 18% 10%;
    min-height: 400px;
  }

  .horizontal_block__wrapper .img__wrapper,
  .vertical_block__wrapper .img__wrapper {
    margin-bottom: 50px;
  }

  .block__title {
    margin-bottom: 24px;
  }

  .img__wrapper {
    max-width: 150px;
    min-height: auto;
  }

  .horizontal_block__wrapper .content__wrapper {
    order: 2;
  }

  .horizontal_block__wrapper .img__wrapper {
    order: 1;
  }

  .horizontal_block__wrapper .border__bottom {
    order: 3;
  }

  .vertical_block__wrapper:hover,
  .horizontal_block__wrapper:hover {
    background: white;
  }
}
