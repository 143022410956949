@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@700;900&display=swap);
:root {
  --cta-red-color: #ff2a33;
  --cta-blue-color: #0055ff;
  --light-blue-color: #e3f2fa;
  --orange-color: #ffbc00;
  --text-black-color: #191925;
  --footer-links-color: rgba(24, 24, 24, 1);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

ul li {
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

button {
  outline: none;
  border: none;
}

p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

:-webkit-any(article, aside, nav, section) h1 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

h6 {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.carousel.carousel-slider {
  border-radius: 24px !important;
}

.carousel .control-dots .dot {
  background: #0047ff !important;
}

.prevent__scrolling {
  overflow: scroll;
}

@media (max-width: 768px) {
  .prevent__scrolling {
    overflow: auto;
  }
}

.header_header__v9FKw,
.header_header__mobile__3CuMV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 130px 0 130px;
  max-width: 1180px;
  margin: auto;
}

.header_logo__block__2pFE4 {
  padding: 35px 0 35px 0;
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-right: 20px;
}

.header_menu__4iihD {
  display: flex;
  padding: 24px 0 24px 0;
  align-items: center;
}

.header_menu__items__3An28 {
  display: flex;
  align-items: flex-start;
  margin-right: 48px;
}

.header_menu__items__3An28 li {
  font-weight: 400;
}

.header_menu__items__3An28 li:hover {
  cursor: pointer;
}

.header_menu__items__3An28 li:not(:last-child) {
  margin-right: 32px;
}

.header_cta__1QGsP {
  padding: 14px 32px;
  background-color: var(--cta-red-color);
  color: white;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  border-radius: 24px;
  white-space: nowrap;
}

.header_cta__1QGsP:hover {
  cursor: pointer;
  background: #ff4b54;
}

.header_cta__1QGsP:active {
  background: #d40009;
}

.header_mobile__menu__26O8w {
  display: none;
}

.header_close__menu__2lX6R {
  display: none;
}

.header_mobile__menu__opened__1PLrX {
  display: none;
}

/* reset mobile layout */
.header_logomenu__wrapper__1sS88 {
  display: flex;
  width: 100%;
}

@media (max-width: 768px) {
  .header_nav__2M5Zj {
    width: 100%;
    height: 100%;
  }

  .header_menu__items__3An28 {
    display: none;
  }

  .header_mobile__menu__opened__1PLrX {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .header_menu__4iihD {
    display: none;
  }

  .header_header__mobile__3CuMV {
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 35px 0 35px;
    max-width: auto;
    width: 100%;
    background-color: white;
    z-index: 9998;
    position: fixed;
    height: 100vh;
    overflow: scroll;
    touch-action: none;
  }

  .header_mobile__logomenu__wrapper__3DF_L {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10%;
  }

  /* button */
  .header_cta__block__mobile__zfIC8 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10%;
    width: 100%;
  }

  .header_cta__block__mobile__zfIC8 .header_cta__1QGsP {
    width: 100%;
  }

  .header_cta__block__GvY6Y {
    display: none;
  }

  .header_mobile__menu__opened__1PLrX .header_menu__items__3An28 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .header_mobile__menu__opened__1PLrX .header_menu__items__3An28 a {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  .header_mobile__menu__opened__1PLrX .header_menu__items__3An28 li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .header_mobile__menu__26O8w {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-basis: 50%;
    padding: 35px 0 35px 0;
  }

  .header_close__menu__2lX6R {
    display: flex;
  }
}

@media (max-width: 576px) {
  .header_cta__block__mobile__zfIC8 {
    bottom: 23%;
  }

  .header_mobile__logomenu__wrapper__3DF_L {
    margin-bottom: 20%;
  }
}

@media (max-width: 999px) {
  .header_header__v9FKw {
    padding: 0px 35px 0px 35px;
  }

  .header_menu__items__3An28 {
    margin-right: 36px;
  }

  .header_menu__items__3An28 li {
    font-size: 14px;
  }

  .header_menu__items__3An28 li:not(:last-child) {
    margin-right: 24px;
  }
}

.firstblock_background__1ox09 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding: 5% 15% 10% 15%;
  max-width: 1180px;
  margin: auto;
}

.firstblock_text__wrapper__2iBfw {
  margin-bottom: 58px;
}

.firstblock_text__1nP0F {
  font-family: 'Source Serif Pro', serif;
  font-size: 72px;
  font-weight: 900;
  text-align: center;
  vertical-align: top;
  max-width: 1000px;
  margin: auto;
}

/* flying button */

.firstblock_button__2nppN {
  background-color: var(--cta-blue-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 70px 20px 70px;
  border-radius: 32px;
  height: 64px;
  left: 40%;
  bottom: 15%;
  z-index: 9999;
  animation-name: firstblock_firstbutton__C9-07;
  animation-duration: 0.3s;
  position: absolute;
}

.firstblock_button__replacement__QXO6U {
  height: 64px;
  padding: 0 70px;
}

.firstblock_button__shifted__2a9L6 {
  background-color: var(--cta-blue-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  position: fixed;
  z-index: 1001;
  border-radius: 50%;
  animation-name: firstblock_test__1mn4e;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.firstblock_button__hide__39WYZ {
  display: none;
}

@keyframes firstblock_test__1mn4e {
  0% {
    bottom: 45%;
    left: 55%;
  }

  100% {
    bottom: 10%;
    left: 92%;
  }
}

.firstblock_button__2nppN:hover,
.firstblock_button__mobile__lKyBQ:hover,
.firstblock_button__shifted__2a9L6:hover {
  cursor: pointer;
  background: #3377ff;
}

.firstblock_button__2nppN:active,
.firstblock_button__shifted__2a9L6:active {
  background: #003bcf;
}

.firstblock_button__2nppN p,
.firstblock_button__mobile__lKyBQ p {
  font-size: 18px;
  color: white;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  white-space: nowrap;
}

.firstblock_svg__first__mobile__324Q6,
.firstblock_svg__second__mobile__28uVp {
  display: none;
}

.firstblock_svg__first__1LaBu {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 10%;
  left: 7%;
}

.firstblock_svg__second__1BwGq {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 5%;
  right: 10%;
}

.firstblock_button__mobile__lKyBQ {
  display: none;
}

@media (max-width: 768px) {
  .firstblock_text__wrapper__2iBfw {
    width: auto;
  }

  .firstblock_text__1nP0F {
    font-size: 47px;
  }

  .firstblock_background__1ox09::before {
    background-image: none;
  }

  .firstblock_svg__first__1LaBu,
  .firstblock_svg__second__1BwGq {
    display: none;
  }

  .firstblock_svg__first__mobile__324Q6,
  .firstblock_svg__second__mobile__28uVp {
    display: flex;
  }

  .firstblock_svg__first__mobile__324Q6 img,
  .firstblock_svg__second__mobile__28uVp img {
    transform: rotate(45deg);
  }

  .firstblock_svg__first__mobile__324Q6 {
    margin-bottom: 10px;
  }

  .firstblock_text__wrapper__2iBfw {
    margin-bottom: 30px;
  }

  .firstblock_button__shifted__2a9L6 {
    animation: none;
    bottom: 5%;
    left: 81%;
  }

  .firstblock_button__2nppN {
    display: none;
  }

  .firstblock_button__mobile__lKyBQ {
    background-color: var(--cta-blue-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 32px;
    display: flex;
    padding: 20px 70px;
  }
}

.services_background__3THBD {
  padding: 10% 10% 12% 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.services_background__3THBD::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url(/static/media/blue_bg.0df7ee50.svg) bottom no-repeat;
  background-size: cover;
  z-index: -1;
}

.services_relative__container__3bRj5 {
  position: relative;
  max-width: 1180px;
  margin: auto;
}

.services_first__oval__3BGvB {
  position: absolute;
  top: 5%;
  left: -2%;
  bottom: 0;
}

.services_second__oval__7CRW2 {
  bottom: 25%;
  right: -10%;
  z-index: 2;
  position: absolute;
}

.services_section__title__1bfBF {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
}

.services_text__highlighted__gwNZQ {
  color: #0147ff;
}

.services_blocks__wrapper__HCXbS {
  border-radius: 24px;
  background-color: white;
  z-index: 1000;
  position: relative;
  display: flex;
  flex-direction: column;
}

.services_horizontal_border__1OWuP img {
  width: 100%;
}

.services_blocks__first_row__3EF-5,
.services_blocks__third_row__3aneg {
  display: grid;
  box-sizing: border-box;
  width: 100%;
}

.services_blocks__second_row__3nyH3 {
  display: flex;
}

.services_border__right__2N_zJ {
  display: block;
}

.services_blocks__first_row__3EF-5,
.services_blocks__third_row__3aneg {
  grid-template-columns: 1fr 1fr 1fr;
}

.services_blocks__second_row__3nyH3 {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 999px) {
  .services_blocks__first_row__3EF-5,
  .services_blocks__second_row__3nyH3,
  .services_blocks__third_row__3aneg {
    grid-template-columns: 1fr;
  }

  .services_blocks__second_row__3nyH3 {
    flex-direction: column;
  }

  .services_border__right__2N_zJ {
    display: none;
  }

  .services_horizontal_border__1OWuP {
    display: none;
  }
}

@media (max-width: 768px) {
  .services_first__oval__3BGvB {
    display: none;
  }

  .services_second__oval__7CRW2 {
    display: none;
  }

  .services_background__3THBD {
    padding: 20% 0 15% 0;
  }

  .services_blocks__wrapper__HCXbS {
    border-radius: 0;
  }

  .services_section__title__1bfBF {
    font-size: 32px;
  }
}

.block_vertical_block__wrapper__3H_Sn,
.block_horizontal_block__wrapper__YEZTx {
  display: flex;
  box-sizing: border-box;
  position: relative;
}

.block_horizontal_block__wrapper__YEZTx {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  width: 50%;
  flex-grow: 1;
}

.block_vertical_block__wrapper__3H_Sn:hover,
.block_horizontal_block__wrapper__YEZTx:hover {
  background: rgba(246, 249, 255, 1);
  cursor: pointer;
}

.block_wrapper__1Afca {
  display: flex;
  flex-basis: 33%;
}

.block_border__right__1lkoi {
  order: 3;
}

.block_vertical_block__wrapper__3H_Sn {
  padding: 15%;
  display: flex;
  flex-direction: column;
  min-height: 590px;
  justify-content: space-around;
  box-sizing: border-box;
}

.block_horizontal_block__wrapper__YEZTx .block_block__title__3b6ub {
  margin-bottom: 24px;
}

.block_horizontal_block__wrapper__YEZTx .block_img__wrapper__2bGPw {
  order: 2;
}

.block_horizontal_block__wrapper__YEZTx .block_content__wrapper__dQUIW {
  margin-right: 25px;
  order: 1;
}

.block_horizontal_block__wrapper__YEZTx .block_border__bottom__3NLjT {
  order: 3;
}

.block_block__title__3b6ub {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 24px;
  opacity: 0.9;
  color: rgba(25, 25, 37, 1);
}

.block_block__text__35rJz {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  opacity: 0.64;
  line-height: 28px;
  font-size: 14px;
}

.block_vertical_block__wrapper__3H_Sn .block_img__wrapper__2bGPw {
  min-height: 200px;
}

.block_vertical_block__wrapper__3H_Sn .block_block__title__3b6ub {
}

.block_border__bottom__3NLjT img,
.block_border__bottom__3NLjT img {
  width: 100%;
}

.block_border__right__1lkoi {
  display: block;
}

.block_border__bottom__3NLjT {
  display: none;
}

@media (max-width: 999px) {
  .block_border__right__1lkoi {
    display: none;
  }

  .block_border__bottom__3NLjT {
    display: block;
  }

  .block_horizontal_block__wrapper__YEZTx {
    flex-direction: column;
    align-items: flex-start;
    padding: 18% 10% 18% 10%;
    width: auto;
  }

  .block_vertical_block__wrapper__3H_Sn {
    padding: 18% 10% 18% 10%;
    min-height: 400px;
  }

  .block_horizontal_block__wrapper__YEZTx .block_img__wrapper__2bGPw,
  .block_vertical_block__wrapper__3H_Sn .block_img__wrapper__2bGPw {
    margin-bottom: 50px;
  }

  .block_block__title__3b6ub {
    margin-bottom: 24px;
  }

  .block_img__wrapper__2bGPw {
    max-width: 150px;
    min-height: auto;
  }

  .block_horizontal_block__wrapper__YEZTx .block_content__wrapper__dQUIW {
    order: 2;
  }

  .block_horizontal_block__wrapper__YEZTx .block_img__wrapper__2bGPw {
    order: 1;
  }

  .block_horizontal_block__wrapper__YEZTx .block_border__bottom__3NLjT {
    order: 3;
  }

  .block_vertical_block__wrapper__3H_Sn:hover,
  .block_horizontal_block__wrapper__YEZTx:hover {
    background: white;
  }
}

.works_background__3NQiH {
  width: 100%;
  height: 100%;
  min-height: 850px;
  box-sizing: border-box;
  position: relative;
  padding: 5% 0 0 0;
  overflow: hidden;
}

.works_text__highlighted__G9feT {
  color: #0147ff;
}

.works_section__title__1mIyk {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 50px !important;
}

.works_works__wrapper__2-AuV {
  margin: 0 auto 60px auto;
  display: flex;
  position: relative;
  min-height: 520px;
  overflow: hidden;
  max-width: 1500px;
}

.works_first__row__19xzC,
.works_second__row__2r9c9 {
  display: flex;
  flex-direction: column;
}

.works_first__row__19xzC > *:not(:last-child),
.works_second__row__2r9c9 > *:not(:last-child) {
  margin-bottom: 20px;
}

.works_first__row__19xzC img,
.works_second__row__2r9c9 img {
  max-width: 325px;
}

.works_first__row__19xzC {
  --scroll: 0;
  display: grid;
  grid-template: repeat(36, var(--cardH)) / var(--cardW);
  position: absolute;
  top: 0;
  left: 57%;
  bottom: 75%;
  transform: translateX(-50%) rotateX(45deg) rotateZ(45deg)
    translateY(var(--scroll));
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}

.works_second__row__2r9c9 {
  --scroll: 0;
  display: grid;
  grid-template: repeat(36, var(--cardH)) / var(--cardW);
  position: absolute;
  top: 60%;
  right: 10%;
  transform: translateX(-50%) rotateX(45deg) rotateZ(45deg)
    translateY(var(--scroll));
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}

.works_art__59tvn {
  position: absolute;
}

@media (max-width: 1100px) {
  .works_first__row__19xzC {
    left: 43%;
    bottom: 80%;
  }
}

@media (max-width: 768px) {
  .works_background__3NQiH {
    min-height: auto;
    padding: 15% 0 10% 0;
  }

  .works_first__row__19xzC {
    left: 65%;
    bottom: 90%;
  }

  .works_second__row__2r9c9 {
    top: 55%;
  }

  .works_first__row__19xzC img,
  .works_second__row__2r9c9 img {
    max-width: 200px;
  }

  .works_section__title__1mIyk {
    font-size: 32px;
    margin-bottom: 32px !important;
  }
}

@media (max-width: 576px) {
  .works_works__wrapper__2-AuV {
    min-height: 280px;
  }

  .works_first__row__19xzC {
    left: 63%;
    bottom: 95%;
  }

  .works_second__row__2r9c9 {
    right: 0;
    top: 75%;
  }

  .works_first__row__19xzC img,
  .works_second__row__2r9c9 img {
    max-width: 170px;
  }

  .works_art__59tvn {
    top: 4%;
  }

  .works_art__59tvn img {
    max-width: 41px;
  }
}

.clients_background__3sKF8 {
  padding: 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.clients_background__3sKF8::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url(/static/media/clients_bg.97ac5d30.svg) bottom no-repeat;
  background-size: cover;
  z-index: -1;
}

.clients_section__title__BkMY9 {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  color: var(--text-black-color);
  margin-bottom: 40px !important;
}

.clients_text__highlighted__KIhvA {
  color: #0147ff;
}

.clients_rows__wrapper__qzelw,
.clients_rows__wrapper__mobile__235vp {
  box-sizing: border-box;
  border-radius: 24px;
  background: white;
  max-width: 1180px;
  margin: auto;
}

.clients_rows__wrapper__mobile__235vp {
  display: none;
}

.clients_row__2HehS {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.clients_horizontal__border__1iNij img {
  width: 100%;
}

@media (max-width: 768px) {
  .clients_rows__wrapper__qzelw {
    display: none;
  }

  .clients_row__2HehS {
    grid-template-columns: 1fr 1fr;
  }

  .clients_rows__wrapper__mobile__235vp {
    display: block;
  }

  .clients_section__title__BkMY9 {
    font-size: 32px;
  }
}

.client_wrapper__1W25x {
  display: flex;
  max-height: 150px;
}

.client_border__right__18pli img {
  max-height: 150px;
}

.client_client__wrapper__MiHaI {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
}

.client_client__wrapper__MiHaI img {
  width: 100%;
  max-width: 130px;
  max-height: 35px;
}

@media (max-width: 768px) {
  .client_wrapper__1W25x {
    max-height: 80px;
  }

  .client_client__wrapper__MiHaI img {
    max-width: 65px;
  }

  .client_border__right__18pli img {
    height: 100%;
  }
}

.numbers_background__1pJho {
  padding: 10% 5% 5% 5%;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 500px;
}

.numbers_heart__art__1tvTc {
  max-height: 400px;
}

.numbers_section__title__1gu-a {
  font-family: 'Source Serif Pro', sans-serif;
  font-size: 56px;
  font-weight: 900;
  text-align: center;
  color: var(--text-black-color);
  /* override global  CSS rule for headings margins */
  margin-bottom: 40px !important;
}

.numbers_text__highlighted__2c-3I {
  color: #0147ff;
}

.numbers_numbers__wrapper__38-j3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1030px;
  margin: auto;
}

.numbers_numbers__wrapper__38-j3 > *:not(:last-child) {
  margin-right: 40px;
}

@media (max-width: 999px) {
  .numbers_background__1pJho {
    padding: 20% 0 20% 0;
  }

  .numbers_numbers__wrapper__38-j3 {
    flex-direction: column;
  }

  .numbers_section__title__1gu-a {
    font-size: 32px;
  }

  .numbers_numbers__wrapper__38-j3 > *:not(:last-child) {
    margin-bottom: 48px;
    margin-right: 0;
  }
}

.number_number__block__2vLXE h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  text-align: center;
}

.number_number__block__2vLXE {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 290px;
}

.number_number__block__2vLXE h6,
.number_number__block__2vLXE p {
  font-family: Montserrat;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
}

@media (max-width: 768px) {
  .number_number__block__2vLXE h1 {
    font-size: 56px;
    margin-bottom: 20px;
  }

  .number_number__block__2vLXE {
    /* to control distance between elements with margins */
    padding: 0;
  }
}

.testimonials_background__2tuqv {
  padding: 10% 10% 10% 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 1;
}

.testimonials_background__2tuqv::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url(/static/media/testimonials_bg.0a8c7130.svg) bottom
    no-repeat;
  background-size: cover;
  z-index: -2;
}

.testimonials_section__title__1yr0s {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
}

.testimonials_text__highlighted__2EzTP {
  color: #0147ff;
}

.testimonials_testimonials__wrapper__desktop__3xc2r {
  background-color: white;
  border-radius: 24px;
  z-index: 1;
  position: relative;
  max-width: 1180px;
  margin: auto;
  display: block;
}

.testimonials_testimonials__art__BHhf5 {
  opacity: 0.5;
  position: absolute;
  top: 7%;
  left: 6%;
  z-index: 0;
}

.testimonials_testimonials__art__BHhf5 img {
  max-width: 100%;
}

.testimonials_border__bottom__217Xx img {
  width: 100%;
}

.testimonials_testimonials__wrapper__mobile__3gZtg {
  display: none;
  position: relative;
}

.testimonials_testimonials__wrapper__mobile__3gZtg::before {
  content: '';
  background: url(/static/media/quotes.f2da21ab.svg) no-repeat;
  position: absolute;
  top: -5%;
  left: 5%;
  z-index: 100;
  height: 44px;
  width: 40px;
}

@media (max-width: 768px) {
  .testimonials_background__2tuqv {
    padding: 15% 10% 15% 10%;
  }

  .testimonials_testimonials__wrapper__mobile__3gZtg {
    display: block;
  }

  .testimonials_testimonials__wrapper__desktop__3xc2r {
    display: none;
  }

  .testimonials_section__title__1yr0s {
    font-size: 32px;
    margin-bottom: 30px !important;
  }

  .testimonials_testimonials__art__BHhf5 img {
    max-width: 38px;
  }
}

.testimonial_testimonial__wrapper__1WlJO {
  display: flex;
  width: 100%;
  padding: 45px;
  box-sizing: border-box;
  border-radius: 24px;
}

.testimonial_quotes__wrapper__2_dp5 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 4em;
  margin-top: 0.5em;
}

.testimonial_content__wrapper__14GQ3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.testimonial_content__14yrV {
  max-width: 650px;
}

.testimonial_content__text__uKxcN {
  margin-bottom: 1em;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: var(--text-black-color);
  opacity: 0.64;
}

.testimonial_content__14yrV h6 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.testimonial_author__info__3TQTZ img {
  margin-right: 1em;
}

.testimonial_author__info__3TQTZ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.testimonial_rating__desktop__2XRE7 {
  display: flex;
  align-items: flex-end;
}

.testimonial_rating__desktop__2XRE7 {
  font-family: Source Serif Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

.testimonial_rating__mobile__1Exfc {
  display: none;
}

.testimonial_author__info__inner__mobile__Q-07v {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .testimonial_testimonial__wrapper__1WlJO {
    background: white;
    border-radius: 24px;
    padding: 32px;
    position: relative;
  }

  .testimonial_rating__desktop__2XRE7 {
    display: none;
  }

  .testimonial_rating__mobile__1Exfc {
    display: flex;
  }

  .testimonial_quotes__wrapper__2_dp5 {
    position: absolute;
    top: -10%;
    left: 3%;
    display: none;
  }

  .testimonial_author__info__3TQTZ {
    justify-content: space-between;
    align-items: center;
  }

  .testimonial_author__info__3TQTZ img {
    max-width: 56px;
  }

  .testimonial_content__text__uKxcN {
    text-align: left;
  }
}

.last_background__2zrZX {
  padding-top: 100px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0047ff;
  overflow: hidden;
  margin-top: -5%;
}

.last_section__title__1Pljp {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
  color: white;
  max-width: 580px;
}

.last_btn__wrapper__2-0Wo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100;
}

.last_cta__button__Qxupj {
  padding: 20px 80px 20px 80px;
  margin: auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #0055ff;
  border-radius: 32px;
  background: white;
  cursor: pointer;
}

.last_cta__button__Qxupj:hover {
  background: #d0dfff;
  cursor: pointer;
}

.last_cta__button__Qxupj:active {
  background: #a5c3ff;
}

@media (max-width: 768px) {
  .last_drops__parallax__3fZ7s {
    display: none;
  }

  .last_circle__parallax__2ggtM {
    display: none;
  }

  .last_section__title__1Pljp {
    font-size: 32px;
    margin-right: 10% !important;
    margin-left: 10% !important;
  }
}

.footer_footer__1lZIc {
  min-height: 100px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1180px;
  margin: auto;
  padding: 0 5% 0 5%;
}

.footer_copyright__328Gf {
  order: 1;
}

.footer_menu__items__2gxoe {
  display: flex;
  order: 2;
}

.footer_menu__items__2gxoe a {
  color: var(--footer-links-color) !important;
}

.footer_menu__items__2gxoe li:not(:last-child) {
  margin-right: 32px;
}

@media (max-width: 768px) {
  .footer_footer__1lZIc {
    flex-direction: column;
    padding: 18% 0 10% 0;
  }

  .footer_menu__items__2gxoe {
    flex-direction: column;
    order: 1;
  }

  .footer_copyright__328Gf {
    order: 2;
  }

  .footer_menu__items__2gxoe {
    margin-bottom: 70px;
    text-align: center;
  }

  .footer_menu__items__2gxoe li:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 32px;
  }
}

