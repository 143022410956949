.background {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding: 5% 15% 10% 15%;
  max-width: 1180px;
  margin: auto;
}

.text__wrapper {
  margin-bottom: 58px;
}

.text {
  font-family: 'Source Serif Pro', serif;
  font-size: 72px;
  font-weight: 900;
  text-align: center;
  vertical-align: top;
  max-width: 1000px;
  margin: auto;
}

/* flying button */

.button {
  background-color: var(--cta-blue-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 70px 20px 70px;
  border-radius: 32px;
  height: 64px;
  left: 40%;
  bottom: 15%;
  z-index: 9999;
  animation-name: firstbutton;
  animation-duration: 0.3s;
  position: absolute;
}

.button__replacement {
  height: 64px;
  padding: 0 70px;
}

.button__shifted {
  background-color: var(--cta-blue-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  position: fixed;
  z-index: 1001;
  border-radius: 50%;
  animation-name: test;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.button__hide {
  display: none;
}

@keyframes test {
  0% {
    bottom: 45%;
    left: 55%;
  }

  100% {
    bottom: 10%;
    left: 92%;
  }
}

.button:hover,
.button__mobile:hover,
.button__shifted:hover {
  cursor: pointer;
  background: #3377ff;
}

.button:active,
.button__shifted:active {
  background: #003bcf;
}

.button p,
.button__mobile p {
  font-size: 18px;
  color: white;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  white-space: nowrap;
}

.svg__first__mobile,
.svg__second__mobile {
  display: none;
}

.svg__first {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 10%;
  left: 7%;
}

.svg__second {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 5%;
  right: 10%;
}

.button__mobile {
  display: none;
}

@media (max-width: 768px) {
  .text__wrapper {
    width: auto;
  }

  .text {
    font-size: 47px;
  }

  .background::before {
    background-image: none;
  }

  .svg__first,
  .svg__second {
    display: none;
  }

  .svg__first__mobile,
  .svg__second__mobile {
    display: flex;
  }

  .svg__first__mobile img,
  .svg__second__mobile img {
    transform: rotate(45deg);
  }

  .svg__first__mobile {
    margin-bottom: 10px;
  }

  .text__wrapper {
    margin-bottom: 30px;
  }

  .button__shifted {
    animation: none;
    bottom: 5%;
    left: 81%;
  }

  .button {
    display: none;
  }

  .button__mobile {
    background-color: var(--cta-blue-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 32px;
    display: flex;
    padding: 20px 70px;
  }
}
