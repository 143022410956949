@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@700;900&display=swap');

:root {
  --cta-red-color: #ff2a33;
  --cta-blue-color: #0055ff;
  --light-blue-color: #e3f2fa;
  --orange-color: #ffbc00;
  --text-black-color: #191925;
  --footer-links-color: rgba(24, 24, 24, 1);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

ul li {
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

button {
  outline: none;
  border: none;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

:-webkit-any(article, aside, nav, section) h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.carousel.carousel-slider {
  border-radius: 24px !important;
}

.carousel .control-dots .dot {
  background: #0047ff !important;
}

.prevent__scrolling {
  overflow: scroll;
}

@media (max-width: 768px) {
  .prevent__scrolling {
    overflow: auto;
  }
}
