.wrapper {
  display: flex;
  max-height: 150px;
}

.border__right img {
  max-height: 150px;
}

.client__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
}

.client__wrapper img {
  width: 100%;
  max-width: 130px;
  max-height: 35px;
}

@media (max-width: 768px) {
  .wrapper {
    max-height: 80px;
  }

  .client__wrapper img {
    max-width: 65px;
  }

  .border__right img {
    height: 100%;
  }
}
