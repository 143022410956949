.number__block h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  text-align: center;
}

.number__block {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 290px;
}

.number__block h6,
.number__block p {
  font-family: Montserrat;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
}

@media (max-width: 768px) {
  .number__block h1 {
    font-size: 56px;
    margin-bottom: 20px;
  }

  .number__block {
    /* to control distance between elements with margins */
    padding: 0;
  }
}
