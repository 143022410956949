.background {
  padding-top: 100px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  min-height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0047ff;
  overflow: hidden;
  margin-top: -5%;
}

.section__title {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
  color: white;
  max-width: 580px;
}

.btn__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100;
}

.cta__button {
  padding: 20px 80px 20px 80px;
  margin: auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #0055ff;
  border-radius: 32px;
  background: white;
  cursor: pointer;
}

.cta__button:hover {
  background: #d0dfff;
  cursor: pointer;
}

.cta__button:active {
  background: #a5c3ff;
}

@media (max-width: 768px) {
  .drops__parallax {
    display: none;
  }

  .circle__parallax {
    display: none;
  }

  .section__title {
    font-size: 32px;
    margin-right: 10% !important;
    margin-left: 10% !important;
  }
}
