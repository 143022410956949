.background {
  padding: 10% 10% 12% 10%;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.background::before {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: url('../../images/services/blue_bg.svg') bottom no-repeat;
  background-size: cover;
  z-index: -1;
}

.relative__container {
  position: relative;
  max-width: 1180px;
  margin: auto;
}

.first__oval {
  position: absolute;
  top: 5%;
  left: -2%;
  bottom: 0;
}

.second__oval {
  bottom: 25%;
  right: -10%;
  z-index: 2;
  position: absolute;
}

.section__title {
  font-size: 56px;
  font-weight: 700;
  font-family: 'Source Serif Pro', sans-serif;
  text-align: center;
  margin-bottom: 40px !important;
  z-index: 2;
}

.text__highlighted {
  color: #0147ff;
}

.blocks__wrapper {
  border-radius: 24px;
  background-color: white;
  z-index: 1000;
  position: relative;
  display: flex;
  flex-direction: column;
}

.horizontal_border img {
  width: 100%;
}

.blocks__first_row,
.blocks__third_row {
  display: grid;
  box-sizing: border-box;
  width: 100%;
}

.blocks__second_row {
  display: flex;
}

.border__right {
  display: block;
}

.blocks__first_row,
.blocks__third_row {
  grid-template-columns: 1fr 1fr 1fr;
}

.blocks__second_row {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 999px) {
  .blocks__first_row,
  .blocks__second_row,
  .blocks__third_row {
    grid-template-columns: 1fr;
  }

  .blocks__second_row {
    flex-direction: column;
  }

  .border__right {
    display: none;
  }

  .horizontal_border {
    display: none;
  }
}

@media (max-width: 768px) {
  .first__oval {
    display: none;
  }

  .second__oval {
    display: none;
  }

  .background {
    padding: 20% 0 15% 0;
  }

  .blocks__wrapper {
    border-radius: 0;
  }

  .section__title {
    font-size: 32px;
  }
}
